import './App.css';
import DiceTableForm from './components/DiceTableForm';
import DiceTableDisplay from './components/DiceTableDisplay';

import {useState} from 'react';

function App() {
	
	const [choreLogs, setChoreLogs] = useState([]);
	const addChoreLog = (log) => {
		let logs = [...choreLogs, log];
		setChoreLogs(logs);
	}

	return (
		<div className="App">


			
			<DiceTableForm addChoreLog={addChoreLog} />

			<DiceTableDisplay chores={choreLogs} />
		</div>
	);
}

export default App;
