import { React, useState } from 'react';

function DiceTableForm({addChoreLog}) {
	const handleSubmit = (event) => {
		addChoreLog([choreDesc, name, date])
		event.preventDefault();
	}

	const dateFormatHelper = (rawDate) => {
		let dateAsString = "";
		
		let years = rawDate.getFullYear().toString();

		let months = (rawDate.getMonth() + 1).toString();

		let days = rawDate.getDate().toString();

		dateAsString = `${years}-${months.padStart(2, '0')}-${days.padStart(2, '0')}`;

		return dateAsString;
	}

	const [choreDesc, setChoreDesc] = useState("");
	const [name, setName] = useState("");
	const [date, setDate] = useState(dateFormatHelper(new Date()));

	return (
		<div>
			<form onSubmit={e => { handleSubmit(e) }}>
				<label>Chore Description</label>
				<br />
				<input
					name='choreDesc'
					type='text'
					onChange={event => setChoreDesc(event.target.value)}
					value={choreDesc}

				/>
				<br />
				<label>Name</label>
				<br />
				<input
					name='userName'
					type='text'
					onChange={event => setName(event.target.value)}
					value={name}
				/>
				<br />
				<label>Date</label>
				<br />
				<input
					name='dt'
					type='date'
					onChange={event => setDate(event.target.value)}
					value={date}
				/>
				<br />
				<input
					className='submitButton'
					type='submit'
					value='Log Chore'
				/>
			</form>
		</div>
	);
}

export default DiceTableForm;